import React from 'react'
import { GalleryData, Gallery } from '../components/Gallery/Gallery'
import { SEO } from '../components/seo'
import { Link } from 'gatsby'

interface Data {
  language: string
  gallery: GalleryData
  intl: {
    messages: { [key: string]: string }
  }
}

const Index: React.FC<{ pageContext: Data }> = props => {
  const {
    pageContext: { language, gallery },
  } = props
  const locales = props.pageContext.intl.messages

  return (
    <>
      <SEO
        title={locales['title']}
        lang={language}
        description="From concept to creation. Leave the building to professionals."
      />

      <header>
        <div className="design-line" />
        <menu>
          <img
            className="menu-logo"
            src="/images/menu-logo.svg"
            alt="Stano Renovatie Logo Roof"
          />
          <ul>
            <li>
              <Link data-scroll to="/#home">
                {locales['menu.home']}
              </Link>
            </li>
            <li>
              <Link data-scroll to="/#services">
                {locales['menu.services']}
              </Link>
            </li>
            <li>
              <Link data-scroll to="/#about">
                {locales['menu.aboutUs']}
              </Link>
            </li>
            <li>
              <Link data-scroll to="/#gallery">
                {locales['menu.gallery']}
              </Link>
            </li>
            <li>
              <Link data-scroll to="/#contact">
                {locales['menu.contact']}
              </Link>
            </li>
          </ul>
        </menu>
        <div className="center-info">
          <img src="/images/logo_final.svg" alt="Stano Renovatie Logo" />
          <h1 data-aos="fade-right">{locales['main.header']}</h1>
          <Link
            className="hero-button"
            data-aos="fade-right"
            data-scroll
            to="/#contact"
          >
            {locales['main.button']}
          </Link>
        </div>
      </header>
      <section className="lower-motto">
        <h2 data-aos="fade-right">{locales['main.description']}</h2>
      </section>

      <section id="services" className="services-section">
        <div className="item">
          <img src="/images/consultationDesign.png" />
          <h3>{locales['service.consultation']}</h3>
        </div>
        <div className="item">
          <img src="/images/completeRenovation.png" />
          <h3>{locales['service.renovation']}</h3>
        </div>
        <div className="item">
          <img src="/images/electricInstallation.png" />
          <h3>{locales['service.electricInstallation']}</h3>
        </div>
        <div className="item">
          <img src="/images/paintingWorks.png" />
          <h3>{locales['service.painting']}</h3>
        </div>
        <div className="item">
          <img src="/images/carpenterWorks.png" />
          <h3>{locales['service.carpenter']}</h3>
        </div>
        <div className="item">
          <img src="/images/tinsmithWorks.png" />
          <h3>{locales['service.tinsmith']}</h3>
        </div>
        <div className="item">
          <img src="/images/wallTilesWorks.png" />
          <h3>{locales['service.walltiles']}</h3>
        </div>
        <div className="item">
          <img src="/images/stuccoWorks.png" />
          <h3>{locales['service.stucco']}</h3>
        </div>
      </section>

      <section id="about" className="about-us">
        <div className="left" />
        <div className="right">
          <article data-aos="fade-left">
            <h3>{locales['about.header']}</h3>
            {locales['about.description']}
          </article>
        </div>
      </section>

      <section className="point-section">
        <div className="point-item" data-aos="fade-left">
          <img src="/images/one.svg" alt="one" />
          <p>{locales['point.1']}</p>
        </div>
        <div className="point-item" data-aos="fade-right">
          <p>{locales['point.2']}</p>
          <img src="/images/two.svg" alt="two" />
        </div>
        <div className="point-item" data-aos="fade-left">
          <img src="/images/three.svg" alt="three" />
          <p>{locales['point.3']}</p>
        </div>
        <div className="point-item" data-aos="fade-right">
          <p>{locales['point.4']}</p>
          <img src="/images/four.svg" alt="four" />
        </div>
        <div className="point-item" data-aos="fade-left">
          <img src="/images/five.svg" alt="five" />
          <p>{locales['point.5']}</p>
        </div>
      </section>
      <section id="gallery">
        <Gallery allLabel={locales['gallery.allcategories']} data={gallery} />
      </section>
      <footer id="contact" className="footer">
        <div className="center">
          <article>
            <h3>{locales['contact.header']}</h3>
            {locales['contact.email']}
            <br />
            {locales['contact.phone']}
            <br />
            {locales['contact.address']}
            <br />
            {locales['contact.city']}
          </article>
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2061.6507730381927!2d4.389856877598087!3d52.084648995432396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5b7dc40b22caf%3A0x1360e4e6f3a564b7!2sSint%20Rapha%C3%ABlstraat%201%2C%202264%20XV%20Leidschendam%2C%20Holandsko!5e0!3m2!1ssk!2ssk!4v1578333324659!5m2!1ssk!2ssk"
              width="300"
              height="250"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen
            />
          </div>
        </div>
      </footer>
    </>
  )
}

export default Index
