import React from 'react'
import Helmet from 'react-helmet'

export const SEO = ({ description, lang, title }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
      ]}
    >
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-156513775-1"
      ></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-156513775-1');`}
      </script>
    </Helmet>
  )
}
