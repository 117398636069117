import React, { useState, useCallback } from 'react'
import './gallery.css'

export interface GalleryImage {
  id: string
  src: string
  alt: string
  tagId: string
}

export interface GalleryImages {
  [id: string]: GalleryImage
}

export interface GalleryTag {
  id: string
  title: string
}

export interface GalleryData {
  images: GalleryImages
  tags: GalleryTag[]
}

export const Gallery: React.FC<{ data: GalleryData; allLabel: string }> = ({
  data: { images, tags },
  allLabel,
}) => {
  // ID of selected tag
  const [selectedTag, setSelectedTag] = useState<string>('0')
  // ID of selected image to show in Modal
  const [selectedImg, setSelectedImg] = useState<string>()
  // Is modal open
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleFilterSelection = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement & { id: string }, MouseEvent>) => {
      e.preventDefault()
      const tagID = e.target['id'] as string
      setSelectedTag(tagID)
    },
    []
  )

  const handleImageSelection = useCallback((imageId: string) => {
    setSelectedImg(imageId)
    setIsOpen(true)
  }, [])

  return (
    <>
      <div className="portfolioFilter">
        <a
          key={0}
          id="0"
          onClick={handleFilterSelection}
          className={selectedTag === '0' ? 'selected' : ''}
        >
          {allLabel}
        </a>
        {tags.map(tag => (
          <a
            key={tag.id}
            id={tag.id}
            onClick={handleFilterSelection}
            className={selectedTag === tag.id ? 'selected' : ''}
          >
            {tag.title}
          </a>
        ))}
      </div>
      <div className="portfolioContainer">
        {Object.keys(images).map(imageID => {
          const handleClick = () => handleImageSelection(imageID)
          return (
            <div
              key={imageID}
              className={`column ${
                selectedTag === images[imageID].tagId || selectedTag === '0'
                  ? 'show'
                  : ''
              }`}
              onClick={handleClick}
            >
              <img src={images[imageID].src} alt={images[imageID].alt} />
            </div>
          )
        })}
      </div>
      {selectedImg && isOpen && (
        <div className="modal">
          <span
            className="close"
            onClick={() => {
              setIsOpen(false)
            }}
          >
            &times;
          </span>
          <img
            className="modal-content"
            src={images[selectedImg].src}
            alt={images[selectedImg].alt}
          />
          <div className="caption">{images[selectedImg].alt}</div>
        </div>
      )}
    </>
  )
}
